import { useApolloClient } from "@apollo/client";
import { debounce, filter, get, includes, isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useState, useMemo } from "react";

import Search from "src/components/Search";
import WorkspacePropType from "src/custom-prop-types/workspace";
import { globalSearchQuery } from "src/queries/globalSearch.graphql";
import * as searchUtil from "src/util/search";
import { reportError } from "src/services/errorReporting";
import { isSearchPage } from "src/util/url";
import { matchPaths } from "src/consts/urlPaths";
import { TAGS_UI_COMPONENTS } from "src/util/consts";
import useTagsSearch from "src/hooks/useTagsSearch";
import { useGlobalSearchContext } from "./GlobalSearchContext";

const SEARCH_COUNT_LIMIT = 3;
const SEARCH_PAGE_COUNT_LIMIT = 20;
const DEBOUNCE_SEARCH_TIME = 500;

const SearchContainer = ({
  onOptionSelect,
  isMulti = false,
  isCloseOnSelect = true,
  isStandardSelect = false,
  includePeople = true,
  includeGroups = true,
  includeTags = true,
  workspace,
  featureFlags,
  showExploreLink = false,
  tagsVisibleIn = TAGS_UI_COMPONENTS.DIRECTORY,
  dropdownOptionsDisplayLimit = 0,
}) => {
  const match = matchPaths();
  const isForSearchPage = isSearchPage(match?.path);
  const [prevOptions, setPrevOptions] = useState();
  const [isFetchingAsyncQuery, setIsFetchingAsyncQuery] = useState(false);
  const [searchError, setSearchError] = useState();
  const client = useApolloClient();
  const groupTypes = get(workspace, "config.groupTypes", {});
  const customer = get(workspace, "config.customer");
  const tagTypesConfig = get(workspace, "config.tagConfig");

  /* hide tags from search that are not to be shown in navigation or are reserved or are type groupAssociation */
  const filteredTagTypesConfig = useMemo(() => {
    return filter(
      tagTypesConfig,
      (config) =>
        includes(config?.visibleIn, TAGS_UI_COMPONENTS.NAVIGATION) &&
        !config?.isReserved &&
        !config?.isGroupAssociation
    );
  }, [tagTypesConfig]);

  const { searchTags } = useTagsSearch({
    tagTypesConfig: filteredTagTypesConfig,
    limit: isForSearchPage ? SEARCH_PAGE_COUNT_LIMIT : SEARCH_COUNT_LIMIT,
    visibleIn: tagsVisibleIn,
    skip: workspace?.config?.featureFlags?.disableGlobalTagsSearch,
  });

  const {
    defaultSearchScope,
    setDefaultSearchScope,
    setSearchResults,
    setSearchData,
  } = useGlobalSearchContext();
  const isScopedSearchEnabled = get(
    featureFlags,
    "scoped-search-filter",
    false
  );

  const getLoadOptions = useCallback(
    (data, tagsSearchResult, inputValue) => {
      const options = searchUtil.getFormattedSearchResults({
        data,
        tagsSearchResult,
        isHideLabels: isStandardSelect,
        isHideExtraLinks: isStandardSelect,
        searchTerm: inputValue,
        customer,
      });
      return options;
    },
    [customer, isStandardSelect]
  );

  const debouncedSearchQuery = useMemo(
    () =>
      debounce((scope, inputValue, callback) => {
        const sanitisedQuery = searchUtil.sanitiseString(inputValue);
        const promises = Promise.all([
          Promise.resolve(
            client.query({
              query: globalSearchQuery,
              variables: {
                size: isForSearchPage
                  ? SEARCH_PAGE_COUNT_LIMIT
                  : SEARCH_COUNT_LIMIT,
                searchTerms: sanitisedQuery,
                includePeople,
                includeGroups,
                includeTags: includeTags && isScopedSearchEnabled,
                filter:
                  isScopedSearchEnabled && !isEmpty(scope)
                    ? {
                        teams: {
                          teams: [scope?.id],
                          includeSubTeams: true,
                          intersectingTeamMembers: true,
                        },
                      }
                    : {},
              },
            })
          ),
          isScopedSearchEnabled && includeTags
            ? []
            : includeTags
            ? Promise.resolve(searchTags(inputValue))
            : [],
        ]);

        promises
          .then(([{ data }, tagsSearchResult]) => {
            const options = filter(
              getLoadOptions(data, tagsSearchResult, inputValue),
              (item) => {
                return item?.options?.length > 0;
              }
            );

            setSearchResults(options);
            setSearchData(data);
            setIsFetchingAsyncQuery(false);
            setSearchError(null);

            const dropdownOptions = options.map((item) => {
              const dropdownItem = { ...(item || {}) };
              if (dropdownOptionsDisplayLimit) {
                dropdownItem.options = dropdownItem?.options?.slice(
                  0,
                  dropdownOptionsDisplayLimit
                );
              }
              return dropdownItem;
            });

            setPrevOptions(dropdownOptions);
            callback(dropdownOptions);
          })
          .catch((error) => {
            setSearchResults([]);
            setSearchData(null);
            reportError(error);
            setIsFetchingAsyncQuery(false);
            setSearchError(error);
            callback([]);
          });
      }, DEBOUNCE_SEARCH_TIME),
    [
      client,
      dropdownOptionsDisplayLimit,
      getLoadOptions,
      includeGroups,
      includePeople,
      includeTags,
      isForSearchPage,
      isScopedSearchEnabled,
      searchTags,
      setSearchData,
      setSearchResults,
    ]
  );

  const loadOptions = (inputValue, callback, scope = defaultSearchScope) => {
    if (!isForSearchPage) {
      setIsFetchingAsyncQuery(true);
      debouncedSearchQuery(scope, inputValue, callback);
    }
  };

  const onDefaultScopeClearCallback = (searchQuery) => {
    setDefaultSearchScope(null);
    loadOptions(searchQuery, () => {}, null);
  };

  return (
    <Search
      isForSearchPage={isForSearchPage}
      isMulti={isMulti}
      isCloseOnSelect={isCloseOnSelect}
      isLoading={isFetchingAsyncQuery}
      isStandardSelect={isStandardSelect}
      onOptionSelect={onOptionSelect}
      loadOptions={loadOptions}
      defaultOptions={prevOptions}
      searchError={searchError}
      groupTypes={groupTypes}
      featureFlags={featureFlags}
      workspace={workspace}
      showExploreLink={showExploreLink}
      onDefaultScopeClearCallback={onDefaultScopeClearCallback}
    />
  );
};

SearchContainer.propTypes = {
  isStandardSelect: PropTypes.bool,
  onOptionSelect: PropTypes.func,
  isCloseOnSelect: PropTypes.bool,
  isMulti: PropTypes.bool,
  includePeople: PropTypes.bool,
  includeGroups: PropTypes.bool,
  featureFlags: PropTypes.object,
  workspace: WorkspacePropType,
  showExploreLink: PropTypes.bool,
  tagsVisibleIn: PropTypes.string,
  includeTags: PropTypes.bool,
  dropdownOptionsDisplayLimit: PropTypes.number,
};

// Wrap in react memo so state provider change doesn't re-render component
export default SearchContainer;
